import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import colors from "../../../constants/style/colors";
import Logo from "../../Elements/Logo";

export interface IProps extends React.Props<any> {
  isOpen: boolean;
  onClick: any;
}
export const MenuHolder = styled("div")`
  position: fixed;
  display: ${({ isOpen }: IProps) => (isOpen ? "flex" : "none")};
  flex-direction: column;
  justify-content: center;
  background-color: ${colors.grey};
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;
export const MenuButtonHolder = styled("div")`
  position: absolute;
  top: 0;
  left: 0;
  width: 100px;
  height: 100px;
`;
export const MenuButtonIconHolder = styled("div")`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 20px;
  left: 45px;
  width: 40px;
  height: 40px;
`;
export const BarIconHolder = styled("div")`
  width: 70px;
  height: 40px;
  border-radius: 5px;
  background-color: #556e86;
  opacity: 1;
  text-align: center;
`;

export const LogoHolder = styled("div")`
  width: 170px;
  height: 70px;
  left 550px;
  position: absolute;
  top: 20px;
  background-color: #556e86;
`;
export const MenuButton = ({ isOpen, onClick }: IProps) => {
  const icon = isOpen ? (
    <FontAwesomeIcon size="2x" icon="times" color={colors.white} />
  ) : (
    <BarIconHolder>
      <FontAwesomeIcon
        size="2x"
        icon="bars"
        color={colors.white}
        style={{ marginTop: "5px" }}
      />
    </BarIconHolder>
  );

  return (
    <>
      <MenuButtonHolder onClick={onClick}>
        <MenuButtonIconHolder>{icon}</MenuButtonIconHolder>
      </MenuButtonHolder>
      <LogoHolder>
        <Logo color="DEFAULT" />
      </LogoHolder>
    </>
  );
};
export const MenuItem = styled("div")`
  text-align: center;
  padding: 10px;
  color: ${colors.white};
  font-size: 20px;
`;

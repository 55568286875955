import styled from "styled-components";
import colors from "../../../constants/style/colors";

export const FooterHolder = styled.footer``;

export const Clearfix = styled.div`
  clear: both;
`;
export const MenuHolder = styled("div")`
  float: right;
  padding: 20px;
`;

export const MenuItem = styled("div")`
  display: block;
  padding: 10px;
  color: grey;
  color: ${colors.grey};
  font-size: 12px;
  cursor: pointer;
  text-align: left;

  :hover {
    color: ${colors.yellow};
  }

  :active {
    color: ${colors.white};
  }
`;

export const LogoHolder = styled.div`
  position: relative;
  padding: 20px 0 0;
  float: left;
  transform: scale(1);
`;

export const LogoText = styled.div`
  position: absolute;
  top: 50px;
  left: 85px;
  width: 50px;
  font-size: 10px;
`;

export const Copyright = styled.div`
  position: relative;
  top: -30px;
  float: left;
  text-align: left;
  padding-bottom: 10px;
  font-size: 11px;
  text-transform: uppercase;
`;

import React from "react";
import colors from "../../../constants/style/colors";

const cls1 = {
  fill: "#101820",
};

const cls2 = {
  fill: "#fff",
};

const cls3 = {
  fill: "#1d1d1b",
};

export const LogoDefault = () => (
  <svg
    id="Laag_1"
    data-name="Laag 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 976.56 432.14"
    style={{ position: "absolute", height: "70px", left: 0 }}
  >
    <polyline style={cls1} points="0 432.14 0 0 490.17 0 490.17 432.14" />
    <g>
      <path
        style={cls2}
        d="M181.19,216.77A79.6,79.6,0,0,1,201.28,219a38.42,38.42,0,0,1,13.52,6.17,23.41,23.41,0,0,1,7.62,9.7,32.67,32.67,0,0,1,2.38,12.73,24.94,24.94,0,0,1-1.09,7.39,21.25,21.25,0,0,1-3.44,6.64,26.7,26.7,0,0,1-6,5.67,37.74,37.74,0,0,1-8.83,4.46q11.25,2.73,16.65,8.83t5.39,15.78a32.61,32.61,0,0,1-2.81,13.52,31,31,0,0,1-8.25,10.91A39.19,39.19,0,0,1,203,328a58.51,58.51,0,0,1-18.21,2.61H139.3V216.77Zm-15.47,19.77v28.14h13.75a46.14,46.14,0,0,0,7.9-.62,16.54,16.54,0,0,0,6-2.19,10.07,10.07,0,0,0,3.75-4.3,16.12,16.12,0,0,0,1.29-6.88,19.28,19.28,0,0,0-1-6.76,9.71,9.71,0,0,0-3.13-4.34,13.15,13.15,0,0,0-5.35-2.34,36.65,36.65,0,0,0-7.7-.71Zm18.6,74.17a23.5,23.5,0,0,0,8.56-1.32,13.65,13.65,0,0,0,5.19-3.44,11.37,11.37,0,0,0,2.58-4.77,20.94,20.94,0,0,0,.71-5.39,18.21,18.21,0,0,0-.82-5.63,9.34,9.34,0,0,0-2.82-4.22,13.74,13.74,0,0,0-5.27-2.66,31,31,0,0,0-8.29-.94H165.72v28.37Z"
        transform="translate(-90.04 -62.13)"
      />
      <path
        style={cls2}
        d="M262.47,262.1a34.06,34.06,0,0,1,8.64-10.31A17.92,17.92,0,0,1,282.32,248a14.51,14.51,0,0,1,8.91,2.58l-1.56,17.82a3.75,3.75,0,0,1-1.29,2.35,4.3,4.3,0,0,1-2.46.62c-.42,0-1,0-1.64-.08s-1.38-.11-2.11-.19l-2.23-.24c-.75-.07-1.44-.11-2.07-.11a12.33,12.33,0,0,0-8.48,2.81A22.2,22.2,0,0,0,264,281.4v49.24H239.81v-81h14.38a12.21,12.21,0,0,1,3,.31,5.34,5.34,0,0,1,2,1,4.19,4.19,0,0,1,1.25,1.75,16.08,16.08,0,0,1,.74,2.66Z"
        transform="translate(-90.04 -62.13)"
      />
      <path
        style={cls2}
        d="M296.86,260.85a49.34,49.34,0,0,1,16.3-9.65A57.09,57.09,0,0,1,332.34,248a35.1,35.1,0,0,1,13.25,2.39,28.48,28.48,0,0,1,10,6.64,28.81,28.81,0,0,1,6.29,10.12A36.36,36.36,0,0,1,364.07,280v50.64H353a11.27,11.27,0,0,1-5.2-.94,7.13,7.13,0,0,1-2.93-3.9l-1.72-4.46c-2,1.72-4,3.23-5.86,4.54a37.28,37.28,0,0,1-5.83,3.32,28.63,28.63,0,0,1-6.4,2,39.82,39.82,0,0,1-7.55.66,33.17,33.17,0,0,1-10.08-1.44,21.23,21.23,0,0,1-7.7-4.26,18.68,18.68,0,0,1-4.88-7,25.11,25.11,0,0,1-1.72-9.65,20.17,20.17,0,0,1,2.19-8.87,21.78,21.78,0,0,1,7.58-8.2,48.08,48.08,0,0,1,14.53-6.22q9.15-2.5,23.06-2.81V280q0-7.11-2.93-10.28c-2-2.11-4.73-3.16-8.32-3.16a22,22,0,0,0-7,.94,31.25,31.25,0,0,0-4.88,2.1c-1.44.79-2.83,1.49-4.18,2.12a11.59,11.59,0,0,1-4.85.93,6.94,6.94,0,0,1-4.22-1.25,10.71,10.71,0,0,1-2.81-3Zm43.61,36.81a81.55,81.55,0,0,0-12,1.25,29.12,29.12,0,0,0-7.42,2.35,8.85,8.85,0,0,0-3.79,3.2,7.53,7.53,0,0,0-1.06,3.91q0,4.14,2.27,5.82t6.64,1.68a19.16,19.16,0,0,0,8.21-1.64,25,25,0,0,0,7.11-5.31Z"
        transform="translate(-90.04 -62.13)"
      />
      <path
        style={cls2}
        d="M453.72,249.6l-31.42,81h-22l-31.42-81H389a7.45,7.45,0,0,1,4.34,1.21,6.08,6.08,0,0,1,2.39,3L407,290.16q1.49,4.45,2.62,8.68c.75,2.81,1.42,5.62,2,8.43a130.81,130.81,0,0,1,4.77-17.11l11.72-36.34a6.11,6.11,0,0,1,2.35-3,6.84,6.84,0,0,1,4.06-1.21Z"
        transform="translate(-90.04 -62.13)"
      />
      <path
        style={cls2}
        d="M478.8,294.46Q480,304.62,485,309.19t12.89,4.57a23.28,23.28,0,0,0,7.27-1,37.46,37.46,0,0,0,5.43-2.27l4.38-2.27a9.17,9.17,0,0,1,4.18-1,5,5,0,0,1,4.38,2.11l7,8.67a36,36,0,0,1-8.09,7,42.52,42.52,0,0,1-8.91,4.22,48.48,48.48,0,0,1-9.14,2.11,68.73,68.73,0,0,1-8.72.58,45.53,45.53,0,0,1-16-2.77,36.79,36.79,0,0,1-13.05-8.25,38.78,38.78,0,0,1-8.83-13.6,50.81,50.81,0,0,1-3.24-18.91A42.73,42.73,0,0,1,457.35,273a37.42,37.42,0,0,1,20.55-21.42,44.9,44.9,0,0,1,31.42-.7,32,32,0,0,1,11.49,7.27,32.9,32.9,0,0,1,7.5,11.68,42.84,42.84,0,0,1,2.7,15.67,35.66,35.66,0,0,1-.23,4.46,7.26,7.26,0,0,1-.86,2.73,3.29,3.29,0,0,1-1.68,1.41,8,8,0,0,1-2.7.39Zm30.48-14.07a21.22,21.22,0,0,0-.74-5.63,13.54,13.54,0,0,0-2.42-4.88,12.37,12.37,0,0,0-4.42-3.48,15,15,0,0,0-6.64-1.33q-6.88,0-10.79,3.95t-5.15,11.37Z"
        transform="translate(-90.04 -62.13)"
      />
    </g>
    <path
      style={cls3}
      d="M710.22,309.47a1.82,1.82,0,0,1,1.33.54l3.13,3.36a53.15,53.15,0,0,1-7.47,6.61,45.47,45.47,0,0,1-8.83,5,51.67,51.67,0,0,1-10.55,3.2,65,65,0,0,1-12.62,1.14,53.67,53.67,0,0,1-21.06-4,47,47,0,0,1-16.45-11.41A51.32,51.32,0,0,1,627,296a67.92,67.92,0,0,1-3.79-23.28,64.92,64.92,0,0,1,3.9-23,52.79,52.79,0,0,1,11-17.86A48.41,48.41,0,0,1,655,220.33a56,56,0,0,1,21.72-4.1,53.47,53.47,0,0,1,20.09,3.52,54.11,54.11,0,0,1,15.94,9.92l-2.42,3.52a2.19,2.19,0,0,1-1.95.93c-.63,0-1.64-.57-3.05-1.71a40.27,40.27,0,0,0-5.86-3.79,48.89,48.89,0,0,0-9.34-3.79,45.83,45.83,0,0,0-13.41-1.73,47.56,47.56,0,0,0-18.32,3.44,40.59,40.59,0,0,0-14.31,9.85A45.38,45.38,0,0,0,634.72,252a65.87,65.87,0,0,0,0,41.66,45.79,45.79,0,0,0,9.34,15.59,40.36,40.36,0,0,0,14,9.77,44.6,44.6,0,0,0,17.47,3.4,64.5,64.5,0,0,0,10.59-.78,41.08,41.08,0,0,0,8.56-2.35,38.1,38.1,0,0,0,7.27-3.83,55.14,55.14,0,0,0,6.64-5.31,4.33,4.33,0,0,1,.78-.51A1.72,1.72,0,0,1,710.22,309.47Z"
      transform="translate(-90.04 -62.13)"
    />
    <path
      style={cls3}
      d="M730.39,261.32a45.11,45.11,0,0,1,12.81-9.22A35.79,35.79,0,0,1,758.44,249a27.74,27.74,0,0,1,10.94,2,20.27,20.27,0,0,1,7.78,5.7,24.91,24.91,0,0,1,4.65,9,41.42,41.42,0,0,1,1.57,11.76v50.65h-3a2.68,2.68,0,0,1-2.89-2.19l-1.09-10a81.71,81.71,0,0,1-6.37,5.63,37.73,37.73,0,0,1-6.6,4.22,33.58,33.58,0,0,1-7.43,2.66,37.93,37.93,0,0,1-8.75.94,25.84,25.84,0,0,1-7.74-1.17,19.54,19.54,0,0,1-6.68-3.6,17.42,17.42,0,0,1-4.69-6.21,21.58,21.58,0,0,1-1.76-9.11,16.19,16.19,0,0,1,2.81-9.14,23.1,23.1,0,0,1,8.83-7.35,58.92,58.92,0,0,1,15.44-5,134.12,134.12,0,0,1,22.63-2.19v-8.13q0-10.79-4.65-16.6T757.66,255a26.06,26.06,0,0,0-9.57,1.56,34.27,34.27,0,0,0-6.68,3.44,42.18,42.18,0,0,0-4.46,3.44,4.81,4.81,0,0,1-2.89,1.57,2.4,2.4,0,0,1-1.41-.39,3.69,3.69,0,0,1-.94-1Zm45.72,29.16A144.53,144.53,0,0,0,757,292.23,54.62,54.62,0,0,0,743.79,296a19.69,19.69,0,0,0-7.7,5.58,11.79,11.79,0,0,0-2.5,7.39,16.31,16.31,0,0,0,1.29,6.76,13.33,13.33,0,0,0,3.44,4.69,13.9,13.9,0,0,0,5,2.7,20.07,20.07,0,0,0,5.86.85,33.47,33.47,0,0,0,8.33-1,31,31,0,0,0,7-2.74,34.22,34.22,0,0,0,6.06-4.18q2.81-2.41,5.55-5.24Z"
      transform="translate(-90.04 -62.13)"
    />
    <path
      style={cls3}
      d="M815.42,264.29a41.17,41.17,0,0,1,12.07-11.17A29.63,29.63,0,0,1,843,249q14.29,0,22.31,9.93t8,29.78a54.61,54.61,0,0,1-2.23,15.9,37.65,37.65,0,0,1-6.56,12.82A31.31,31.31,0,0,1,853.91,326a32.25,32.25,0,0,1-14.5,3.12,30.25,30.25,0,0,1-13.68-2.89,28.2,28.2,0,0,1-10-8.52v37.91h-7.5V250.3h4.14a2.09,2.09,0,0,1,2.27,2Zm.31,46.82a32.71,32.71,0,0,0,4.73,5.66,24,24,0,0,0,5.2,3.76,21.34,21.34,0,0,0,5.82,2.06,32.56,32.56,0,0,0,6.52.63,27.26,27.26,0,0,0,12-2.5,23.59,23.59,0,0,0,8.63-7.07,31.83,31.83,0,0,0,5.24-10.95,52.65,52.65,0,0,0,1.76-14q0-17.35-6.41-25.52T840.9,255a25.44,25.44,0,0,0-14,4,39.54,39.54,0,0,0-11.18,11.1Z"
      transform="translate(-90.04 -62.13)"
    />
    <path
      style={cls3}
      d="M905.92,223.5a5.75,5.75,0,0,1-.59,2.61,7.82,7.82,0,0,1-1.52,2.15,7.2,7.2,0,0,1-2.19,1.49,6.68,6.68,0,0,1-5.31,0,7,7,0,0,1-2.19-1.49,7.15,7.15,0,0,1-1.49-2.15,6.29,6.29,0,0,1-.54-2.61,6.92,6.92,0,0,1,.54-2.7,7,7,0,0,1,1.49-2.23,6.94,6.94,0,0,1,2.19-1.48,6.68,6.68,0,0,1,5.31,0,7.17,7.17,0,0,1,2.19,1.48,7.65,7.65,0,0,1,1.52,2.23A6.32,6.32,0,0,1,905.92,223.5Zm-3.2,26.8v77.77h-7.43V250.3Z"
      transform="translate(-90.04 -62.13)"
    />
    <path
      style={cls3}
      d="M950.47,329.32q-7.9,0-12.31-4.38t-4.42-13.52V258.28H922.57a2.09,2.09,0,0,1-1.41-.47,1.66,1.66,0,0,1-.55-1.33v-2.89l13.29-.94,1.87-27.82a2.94,2.94,0,0,1,.63-1.26,1.65,1.65,0,0,1,1.33-.54h3.52v29.7h24.54v5.55H941.25V311a16.44,16.44,0,0,0,.82,5.51,10.29,10.29,0,0,0,2.26,3.79,8.89,8.89,0,0,0,3.4,2.19,12.33,12.33,0,0,0,4.22.7,12.89,12.89,0,0,0,4.85-.82,20.25,20.25,0,0,0,3.52-1.8,23.43,23.43,0,0,0,2.42-1.8,2.66,2.66,0,0,1,1.48-.81,1.68,1.68,0,0,1,1.25.78l2,3.28a21.5,21.5,0,0,1-7.54,5.27A23.5,23.5,0,0,1,950.47,329.32Z"
      transform="translate(-90.04 -62.13)"
    />
    <path
      style={cls3}
      d="M980.56,261.32a45.11,45.11,0,0,1,12.81-9.22,35.86,35.86,0,0,1,15.25-3.13,27.81,27.81,0,0,1,10.94,2,20.31,20.31,0,0,1,7.77,5.7,24.91,24.91,0,0,1,4.65,9,41.42,41.42,0,0,1,1.57,11.76v50.65h-3a2.68,2.68,0,0,1-2.89-2.19l-1.09-10a81.71,81.71,0,0,1-6.37,5.63,37.73,37.73,0,0,1-6.6,4.22,33.58,33.58,0,0,1-7.43,2.66,37.87,37.87,0,0,1-8.75.94,25.84,25.84,0,0,1-7.74-1.17,19.54,19.54,0,0,1-6.68-3.6,17.42,17.42,0,0,1-4.69-6.21,21.58,21.58,0,0,1-1.76-9.11,16.12,16.12,0,0,1,2.82-9.14,23,23,0,0,1,8.83-7.35,58.71,58.71,0,0,1,15.43-5,134.12,134.12,0,0,1,22.63-2.19v-8.13q0-10.79-4.65-16.6t-13.8-5.83a26.06,26.06,0,0,0-9.57,1.56,34.27,34.27,0,0,0-6.68,3.44,42.18,42.18,0,0,0-4.46,3.44,4.81,4.81,0,0,1-2.89,1.57,2.4,2.4,0,0,1-1.41-.39,3.66,3.66,0,0,1-.93-1Zm45.72,29.16a144.53,144.53,0,0,0-19.11,1.75A54.75,54.75,0,0,0,994,296a19.69,19.69,0,0,0-7.7,5.58,11.79,11.79,0,0,0-2.5,7.39,16.31,16.31,0,0,0,1.29,6.76,13.33,13.33,0,0,0,3.44,4.69,13.9,13.9,0,0,0,5,2.7,20.07,20.07,0,0,0,5.86.85,33.41,33.41,0,0,0,8.33-1,31,31,0,0,0,7-2.74,34.22,34.22,0,0,0,6.06-4.18q2.81-2.41,5.55-5.24Z"
      transform="translate(-90.04 -62.13)"
    />
    <path
      style={cls3}
      d="M1066.6,214.35V328.07h-7.5V214.35Z"
      transform="translate(-90.04 -62.13)"
    />
  </svg>
);

export const LogoBlack = () => (
  <svg height="100" width="100">
    <g transform="matrix(1.3333333,0,0,-1.3333333,0,100) scale(0.02)">
      <path
        style={{
          fill: "none",
          strokeWidth: "5.66930008",
          strokeLinecap: "butt",
          strokeLinejoin: "miter",
          strokeDasharray: "none",
          strokeOpacity: 1,
        }}
        d="M10 3610h3600V10H10z"
      />
      <path
        style={{
          fill: "#ffbe0b",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        d="M1413.95 910.137c0 9.949 3.4 18.332 10.23 25.429 6.91 7.008 15.28 10.493 25.15 10.493 9.95 0 18.24-3.485 25.15-10.493 6.81-7.097 10.23-15.48 10.23-25.429 0-9.668-3.42-17.965-10.23-24.692-6.91-6.726-15.2-10.125-25.15-10.125-9.87 0-18.24 3.399-25.15 10.125-6.83 6.727-10.23 15.024-10.23 24.692"
      />
      <path
        style={{
          fill: "#ffbe0b",
          fillOpacity: 1,
          fillRule: "evenodd",
          stroke: "none",
        }}
        d="M2974.75 2744.68H775.949v-771.54h70.735v700.8H2904.02V946.059H1573.04V875.32h1401.71v1869.36"
      />
      <path
        style={{
          fill: colors.black,
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        d="M1675.08 1670.74h160.03c0-2.74.09-6.61.28-11.61.56-13.1.84-21.87.84-26.49 0-56.86-16.31-101.09-48.84-132.49-32.62-31.49-78.55-47.24-137.77-47.24-33.19 0-62.71 4.9-88.64 14.61-25.93 9.82-48.75 24.53-68.17 44.23-19.15 19.52-33.85 42.44-44.04 68.84-10.28 26.31-15.37 54.5-15.37 84.48 0 30.56 5.19 58.75 15.56 84.59 10.47 25.74 25.84 48.47 46.3 68.18 19.71 19.33 42.81 34.04 69.31 44.22 26.5 10.09 54.88 15.19 85.05 15.19 31.78 0 60.36-5.86 85.43-17.45 25.09-11.6 47.53-29.33 67.24-53.28l-47.9-36.21c-13.68 15.84-29.05 27.63-46.21 35.37-17.07 7.73-36.21 11.59-57.43 11.59-43.84 0-79.58-14.05-107.3-42.15-27.73-28.1-41.59-64.59-41.59-109.48 0-45.73 13.86-82.79 41.59-111.17 27.72-28.48 63.65-42.72 107.87-42.72 37.53 0 67.14 8.77 88.64 26.22 21.59 17.53 32.34 41.3 32.34 71.57v5.75h-97.22v55.45m-646.87 197.46h65.06v-245.65c0-36.4 8.39-63.84 25.28-82.14 16.88-18.38 42.06-27.53 75.62-27.53 33.29 0 58.37 9.15 75.25 27.53 16.88 18.3 25.27 45.74 25.27 82.14v245.65h65.63v-251.69c0-54.69-13.76-95.71-41.39-122.87-27.63-27.15-69.31-40.73-124.95-40.73-55.63 0-97.12 13.58-124.56 40.64-27.44 27.06-41.21 68.09-41.21 122.96v251.69m-246.585-404.54v185.11L645.273 1868.2h69.122l84.863-137.3c2.074-3.58 4.527-8.11 7.644-13.87 3.016-5.65 6.028-11.88 9.145-18.48 2.637 6.41 5.371 12.54 8.203 18.2 2.828 5.75 5.844 10.93 8.953 15.75l86.942 135.7h65.73l-139.184-219.43v-185.11h-65.066M961.07 891.934v440.916l269.61-259.23c7.31-7.32 14.72-15.13 22.35-23.42 7.52-8.32 15.42-17.72 23.74-28.1v294.13h63.51V875.316l-274.87 264.094c-7.32 7.2-14.45 14.73-21.27 22.63-6.92 7.92-13.35 16.24-19.56 24.94V891.934h-63.51m-315.824 0v424.296h236.797v-58.73H714.109v-106.24h167.934v-60.51H714.109V953.664h167.934v-61.73H645.246"
      />
    </g>
  </svg>
);

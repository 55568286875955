import React from "react";
import { Link as GLink } from "gatsby";
// @ts-ignore
import { Link } from "react-scroll";
import { useStaticQuery, graphql } from "gatsby";
import { setConfiguration } from "react-grid-system";
import Logo from "../../Elements/Logo";
import SectionContent from "../../Elements/SectionContent";
import {
  FooterHolder,
  MenuHolder,
  MenuItem,
  Clearfix,
  LogoHolder,
  LogoText,
  Copyright,
} from "./Footer.style";

setConfiguration({ gridColumns: 24 });

export interface Props {
  isLandingPage?: boolean;
}

export interface Query {
  allContentfulMenu: {
    edges: {
      node: {
        name: string;
        order: number;
      };
    }[];
  };
}

const query = graphql`
  query {
    allContentfulMenu {
      edges {
        node {
          name
          order
        }
      }
    }
  }
`;

export default ({ isLandingPage }: Props) => {
  const {
    allContentfulMenu: { edges },
  }: Query = useStaticQuery(query);
  const items = edges
    .sort((a, b) => a.node.order - b.node.order)
    .filter((item) => item.node.order !== 0)
    .map((item) => (
      <MenuItem key={item.node.order}>
        {isLandingPage ? (
          <Link
            to={`#${item.node.name}`}
            spy={true}
            smooth={true}
            offset={-100}
            duration={500}
          >
            {item.node.name}
          </Link>
        ) : (
          <GLink to="/">{item.node.name}</GLink>
        )}
      </MenuItem>
    ));

  return (
    <SectionContent>
      <FooterHolder>
        <LogoHolder>
          <Link to={`#INTRO`} spy={true} smooth={true} duration={500}>
            <Logo color="DEFAULT" />
          </Link>
        </LogoHolder>
        <MenuHolder>{items}</MenuHolder>
        <Clearfix />
        <Copyright>
          all rights reserved. © {new Date().getFullYear()} Brave Capital
        </Copyright>
      </FooterHolder>
    </SectionContent>
  );
};

/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import "normalize.css";
import "antd/es/grid/style/css";
import { LayoutCon } from "./Layout.style";
import { Helmet } from "react-helmet";
import "./layout.css";

export interface Props {
  children: React.ReactNode;
  isLandingPage?: boolean;
}

export default ({ children, isLandingPage }: Props) => (
  <LayoutCon>
    <Helmet>
      <meta
        name="google-site-verification"
        content="6x1lV2fWkVZV71yMQoyzP_Bi4Bn4UdPOUOc42Jx21og"
      />
    </Helmet>
    <Header isLandingPage={isLandingPage} />
    <div>
      <main>{children}</main>
      <Footer isLandingPage={isLandingPage} />
    </div>
  </LayoutCon>
);

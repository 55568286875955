import styled from "styled-components";
import colors from "../../../constants/style/colors";

interface PropsHeaderHolder {
  isSticky: boolean;
}

export const HeaderHolder = styled("header")`
  position: ${({ isSticky }: PropsHeaderHolder) =>
    isSticky ? "fixed" : "absolute"};
  width: 100vw;
  z-index: 50;
  display: flex;
  top: ${({ isSticky }: PropsHeaderHolder) => (isSticky ? 0 : 60)}px;
  justify-content: center;

  @media (max-width: 880px) {
    position: fixed;
    top: 0;
  }
`;

export const HeaderContent = styled("div")`
  display: flex;
  justify-content: center;
  width: 90vw;
  max-width: 1600px;
  position: relative;
`;

export const MenuHolder = styled("div")`
  position: absolute;
  /* left: 0; */
  right: 60px;
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  height: 70px;
  /* padding-left: 35vw; */
  /* padding-right: 60px; */
`;

export const MenuItem = styled("div")`
  display: inline-block;
  padding: 10px 30px;
  cursor: pointer;

  color: ${({ isCallToAction }: { isCallToAction: boolean }) =>
    isCallToAction ? colors.yellow : colors.white};

  border: 1px
    ${({ isCallToAction }: { isCallToAction: boolean }) =>
      isCallToAction ? "solid" : "none"};
  ${colors.yellow};

  :hover {
    background-color: ${({ isCallToAction }: { isCallToAction: boolean }) =>
      isCallToAction ? colors.yellow : "initial"};

    color: ${({ isCallToAction }: { isCallToAction: boolean }) =>
      isCallToAction ? colors.grey : colors.yellow};

    transition: background-color 1s ease;
  }

  :active {
    color: ${colors.grey};
  }
`;

interface MenuBackgroundInterface extends PropsHeaderHolder {
  isActive: boolean;
}

export const MenuBackground = styled("div")`
  position: ${({ isSticky }: MenuBackgroundInterface) =>
    isSticky ? "fixed" : "absolute"};
  opacity: ${({ isActive }: MenuBackgroundInterface) => (isActive ? 1 : 0.25)};
  background-color: ${({ isActive }: MenuBackgroundInterface) =>
    isActive ? "#556E86" : colors.contrastColour};
  height: 70px;
  width: 100vw;
  top: ${({ isSticky }: MenuBackgroundInterface) => (isSticky ? 0 : 60)}px;
  z-index: 20;
  transition: opacity 0.4s ease-in-out;

  @media (max-width: 880px) {
    height: 40px;
    width: 40px;
    top: 20px;
    left: 87px;
    border-radius: 5px;
    color: transparent;
    display: none;
  }
`;

export const LogoHolder = styled.div`
  @media (min-width: 880px) {
    margin-left: 0;
  }
`;

/*
Original navbar styling
0F2643
  position: ${({ isSticky }: MenuBackgroundInterface) =>
    isSticky ? "fixed" : "absolute"};
  opacity: ${({ isActive }: MenuBackgroundInterface) => (isActive ? 1 : 0)};
  background-color: ${colors.grey};
  height: 70px;
  width: 100vw;
  top: ${({ isSticky }: MenuBackgroundInterface) => (isSticky ? 0 : 60)}px;
  z-index: 20;
  transition: opacity 0.4s ease-in-out;



*/

const colors = {
  white: "#ffffff",
  black: "#000000",
  lightGrey: "#f6f6f6",
  grey: "#222222",
  yellow: "#D92419",
  contrastColour: "#ffffe9",
  navbarRed: "#FE492D",
};

export default colors;
